<template>
  <div class="about">
    <div class="subpage-banner">
      <div class="container">
        <div class="row">
          <div class="col-12 text-white">
            <h2>About us</h2>
            <p>
              ELEVATE is one of the nation’s
              premier membership organization.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="subpage-body">
            <div class="elevate-fount">
              <h1>ELEVATE was founded back in 1993 with 6 basic principles.</h1>

              <ol>
                <li>
                  To encourage, develop, and promote improved Health and
                  Wellness standards for all members.
                </li>
                <li>
                  To create the ability for all members to have access to
                  knowledge and information for all Health and Wellness issues.
                </li>
                <li>
                  To provide education about health and wellness issues and
                  developments.
                </li>
                <li>
                  To be a voice for our members to be able to be heard on
                  matters that concern the quality and availability of
                  healthcare and other issues that concern same.
                </li>
                <li>
                  To do any and all other things lawful and proper for such an
                  association in the promotion of the common interests of its
                  members.
                </li>
                <li>
                  To have a resource for the purchase of their Health and
                  Wellness products including benefit plans for the member and
                  their families.
                </li>
              </ol>

              <p>
                ELEVATE is one of the nation’s premier membership organization for assisting individuals with
                various types of Health and Wellness concerns. ELEVATE supports
                a strong fundamental belief in putting our member’s needs first!
                Our mission is the lead the new age of health and wellness
                services between the quality and the cost of healthcare
                services. As a member of ELEVATE we expect that you will promote
                the highest standards of honestly and personal integrity. We
                promote various wellness programs that are readily accessible
                for you and your family and we hope you maintain a well-balanced
                and healthy life style.
              </p>
            </div>

            <div class="what-elevate-offer">
              <h3>What ELEVATE can offer you</h3>
              <p>
                ELEVATE has partnered with several groups and associations to
                offer superior health and wellness plans. Our plans make logical
                sense. Unlike most current health plans, nearly every plan we
                work with will give you your annual physical at no charge as
                well as offer you copays for both your primary and specialist
                doctor visits, Rx, urgent care and a diagnostic benefits all
                before having to satisfy a deductible. We also pride ourselves
                on being one of the few associations left to offer high
                deductible, HSA eligible plans. HSA eligible plans are a great
                way to control you healthcare spending on top of reducing your
                personal taxes.
              </p>
              <p>
                ELEVATE can also you access to some of the best dental and
                vision plans across the country. We offer both PPO and dHMO
                dental plans at a very affordable cost. These plans boast
                extensive, national networks, little to no waiting periods for
                preventative or basic service as well as offer higher Annual Max
                to cover all your dental needs. Our vision plans also utilize a
                national provider network, and will cover glasses, contacts and
                annual check-ups.
              </p>
              <p>
                On top of that, we can also offer you Accident plans to help
                cover the unforeseen occurrences as well as Critical Illness
                plans to help with medical expenses pertaining to 10 key
                conditions such as cancer, heart attacks and more terminal
                illnesses.
              </p>
              <p>
                Finally, ELEVATE always looks towards the final insurance
                payment, Life Insurance. That is why we include an AD&amp;D
                policy on nearly all of our programs. However, if you are
                looking to protect your family and your assets, we will be there
                for you, today and tomorrow. Let us compare up to 5 major
                carriers to ensure you will be covered properly. Don’t forget to
                ask about adding Riders to you coverage as well.
              </p>
            </div>

            <div class="careers-elevate">
              <h3>Careers at ELEVATE</h3>
              <p>
                At ELEVATE we are always looking to expand our broker market. We
                are firm believers in personal and actual human interaction in a
                cyber world. That is why nearly all of our products require
                actual signatures and a consultation with our brokers. If you
                are looking to build a long career, with a strong support
                system, reach out to one of offices and we can get you set up to
                begin offering membership into our association.
              </p>
            </div>
          </div>
        </div>
        <span class="line-top-wrap"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>
